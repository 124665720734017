import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../components/common/customMedia'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'
import { Image } from '../components/common/Image'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" keywords={[]} />
    <Container>
      <ImageWrapper>
        <Image path="404@2x.png" />
      </ImageWrapper>
      <Text>お探しのページは見つかりませんでした</Text>
    </Container>
  </Layout>
)

const Container = styled.div`
  ${customMedia.lessThan("medium")`
    padding-top: 42px;
    padding-bottom: 200px;
  `}
  ${customMedia.greaterThan("medium")`
    padding-top: 80px;
    padding-bottom: 100px;
  `}

  background-color: rgb(239, 239, 239);
`

const ImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 150px auto 0;
    width: 300px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 120px auto 0;
    width: 450px;
  `}
`

const Text = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.7rem;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.5rem;
    margin: 25px
  `}

  color: #26d2ad;
  text-align: center;
  font-weight: bold;
`

export default NotFoundPage
